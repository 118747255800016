<template>
  <div>
    <mdb-parallax
    src="https://i.ibb.co/XZ8Rzdn/artificial.jpg"
    height="full"
    factor="1.0">
    </mdb-parallax>

    <mdb-parallax
    src="https://i.ibb.co/dG6TSZs/braunsche-roehre3.jpg"
    height="full"
    factor="1.0">
    </mdb-parallax>

    <mdb-parallax
    src="https://i.ibb.co/ZG4nR0P/artificial-rotated.jpg"
    height="full"
    factor="1.0">
    </mdb-parallax>

    <mdb-parallax
    src="https://i.ibb.co/fSGSGZs/braunsche-roehre.jpg"
    height="full"
    factor="1.0">
    </mdb-parallax>
  </div>  
</template>

<script>
export default {
  name: 'Parallax',
    components: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

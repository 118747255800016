<template>
  <div class="whole-page">

        <div class="podium pt-3 d-flex justify-content-center">
          <img
            src="https://i.ibb.co/tXkdhyP/planet-white-transparent.png"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="3D Blender Model of Earth"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"EARTH" 3D MODEL</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/ChpFczZ/Power-of-Death.png"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Gemälde - Power of Death abstrahiert"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"POWER OF DEATH" CANVAS</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div>  

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/MSjmFNv/Bosch-Part-2.png"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Gemälde - The Garden of Earthly Delights"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"THE GARDEN OF EARTHLY DELIGHTS" CANVAS</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/4th5v6B/View-of-Toledo-Normal.png"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Gemälde - View of Toledo"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"VIEW OF TOLEDO" CANVAS</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div>  

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/K711C0t/Christ-in-the-Storm.png"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Gemälde - Christ in the Storm abstrahiert und multipliziert"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"THE STORM ON THE SEA OF GALILEE" CANVAS</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div>  

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/cgmxTyY/dark-sphere-opac.png"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Blender Render - A dark sphere with a tile of blue shimmer inside"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"DEATH STRANDING" 3D MODEL</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/VJtXfx9/artefact-opacs.png"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Blender Render - An unidentified artefact from an unknown source"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"THE ARTEFACT" 3D MODEL</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/L1hbRV3/Cloud.jpg"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Photography - Cloud by Day"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"TIP OF THE CLOUD" PHOTOGRAPHY</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div>  

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/pwLmKCP/cron-cut.jpg"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Blender Render - Noisy Orb"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"THE ORB" 3D MODEL</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/Kq3JH3z/rings-opac.png"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Blender Render - Rings and Planets"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"SPACE IN A POCKET" 3D MODEL</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/QfNkK6K/War.jpg"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Photography - War"
          />
        </div>
        

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"WAR" PHOTOGRAPHY</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/XXryzcG/castle-600-r.png"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Blender Render - Creatorlabs Office as a Castle"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"CREATORLABS CASTLE" 3D MODEL</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/MNm13m7/white-neon.jpg"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Photography - 5 Words In Green Neon BW filtered"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"WHITE NEON" PHOTOGRAPHY</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/ky7Vf0P/city-rotated.jpg"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Photography - Buildings of London and Nature"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"LONDON EXCERPT" PHOTOGRAPHY</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/WfrmB7r/Power-of-Water-Black.png"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Gemälde - The Great Wave off Kanagawa"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"THE GREAT WAVE OFF KANAGAWA" CANVAS</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/8d03Q8X/Mujer-con-Perro.png"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Gemälde - Mujer con Perro"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"MUJER CON PERRO" CANVAS</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div>  

        <div class="podium d-flex justify-content-center">
          <img
            src="https://i.ibb.co/1v8xPgs/Rotation-of-Earth.jpg"
            class="img-fluid hover-shadow d-flex justify-content-center"
            alt="Photography - Long exposure night shot with visible rotation of Earth"
          />
        </div>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container4">
                <h2 class="hidden-text">"ROTATION OF EARTH" PHOTOGRAPHY</h2>
                </div>
            </mdb-col>
            </mdb-row>
        </mdb-mask>
        </div> 

  </div>
</template>

<script>
export default {
  name: 'ImageSet',
    components: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.whole-page {
  background-color: black;
  max-width: 100vw;
  overflow-x: hidden;
}
.matrix { 
  padding: 50px;
}
.hidden-text {
  font-size: 1.5rem;
  color: black;
  font-style: italic;
  opacity: 0;
  padding: 30px;
  transition: box-shadow .3s;
  transition: opacity .9s;
  -webkit-transition: color 1s ease-out;
  -moz-transition: color 1s ease-out;
  -o-transition: color 1s ease-out;
  transition: color 1s ease-out;
}
.hidden-text:hover {
  color: #fff9ea;
  cursor: default;
}
.container4 {
  margin-bottom: 40px;
}
.container4:hover .hidden-text{
  color: #fff9ea;
  opacity: 1;
}
.podium {
  max-width: 40vw;
  height: auto;
  text-align: center;
  margin: auto;
  transition: transform 1s; /* Animation */
}
.podium:hover {
  transform: scale(1.5);
}

  @media only screen and (max-width: 900px) {
    .podium {
      max-width: 80vw;
      height: auto;
    }
    .podium:hover {
      transform: scale(1.1);
    }
  }

</style>

<template>
  <!-- Footer -->
  <mdb-footer class="footer font-small pt-0">
    <mdb-container>
      <mdb-row class="bottom-nav pt-1 text-center d-flex justify-content-center">
          <!--
        <mdb-col md="2" class="b-3">
            <router-link to="/welcome">
                <h6 class="text title font-weight-bold"><a>HOME</a></h6>
            </router-link>
        </mdb-col>

        <mdb-col md="2" class="b-3">
            <router-link to="/inside">
                <h6 class="text title font-weight-bold"><a>SANDBOX</a></h6>
            </router-link>
        </mdb-col>

        <mdb-col md="2" class="b-3">
          <router-link to="/my">
            <h6 class="text title font-weight-bold"><a>GALERY</a></h6>
          </router-link>
        </mdb-col>

        <mdb-col md="2" class="b-3">
            <router-link to="/head">
                <h6 class="text title font-weight-bold"><a>SEARCH</a></h6>
            </router-link>
        </mdb-col>
        -->

        <mdb-col md="5" class="b-3">
          <a href="https://www.creatorlabs.dev">CONTACT</a>
        </mdb-col>

        <mdb-col md="5" class="b-3">
          <a href="#app">TO THE TOP</a>
        </mdb-col>


      </mdb-row>
      <hr class="rgba-white-dark" style="margin: '0 15%'" />
      <hr class="clearfix d-md-none rgba-white-light" style="margin: '10% 15% 5%'" />
      <!--<mdb-row class="pb-3">
        <mdb-col md="12">
          <div class="mb-5 flex-center">
            <a class="fb-ic"><i class="fab fa-facebook fa-lg white-text mr-md-4"> </i></a>
            <a class="tw-ic"><i class="fab fa-twitter fa-lg white-text mr-md-4"> </i></a>
            <a class="gplus-ic"><i class="fab fa-google-plus fa-lg white-text mr-md-4"> </i></a>
            <a class="li-ic"><i class="fab fa-linkedin-in fa-lg white-text mr-md-4"> </i></a>
            <a class="ins-ic"><i class="fab fa-instagram fa-lg white-text mr-md-4"> </i></a>
            <a class="pin-ic"><i class="fab fa-pinterest fa-lg white-text"> </i></a>
          </div>
        </mdb-col>
      </mdb-row>
      -->
    </mdb-container>
    <div class="artist footer-copyright text-center pb-3">
      <mdb-container fluid>
         © Jakob Lange, 2021
      </mdb-container>
    </div>
  </mdb-footer>
  <!-- Footer -->
</template>

<script>
  import { mdbFooter, mdbContainer, mdbRow, mdbCol } from 'mdbvue';
  export default {
    name: 'Footer',
    components: {
      mdbFooter,
      mdbContainer,
      mdbRow,
      mdbCol
    }
  }
</script>

<style scoped>
.text {
  text-decoration: none;
  color: white;
  font-size: larger;
}
.title {
    font-size: larger;
}
.text:hover {
  text-decoration: none;
  color: black;
 
}
.bottom-nav {
    opacity: 0.8;
}
.navbar .dropdown-menu a:hover {
  color: inherit !important;
}
.artist {
    opacity: 0.5;
}
</style>
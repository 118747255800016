<template>
  <div>
    <div class="box">
    </div> 
    <div class="video d-flex justify-content-center">
        <iframe width="1865" height="783" src="https://www.youtube.com/embed/ThDYazipjSI?autoplay=1&controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
    </div>
  </div>  
</template>

<script>
export default {
  name: 'EasterEgg',
    components: {
    }
}
</script>

<style scoped>
.box {
    height: 100px;
}
</style>

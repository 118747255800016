<template>
    <div>
        <mdb-view class="d-flex justify-content-center">
          <img
            src="https://i.ibb.co/3h3MKJW/Ebene-4.png"
            class="hover-shadow d-flex justify-content-center toto"
            alt="Embrace Love"
          />
            <mdb-mask class="mask" flexCenter>
            <mdb-row class="text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container2">
                <h1 class="landing-text-n">IT'S  ALL  A  MATTER  OF  PERSPECTIVE</h1>
                </div>
            </mdb-col>
            </mdb-row>
            </mdb-mask>
        </mdb-view>

        <div class="matrix">
        <mdb-mask flexCenter>
            <mdb-row class="text-center">
            <mdb-col class="mx-auto">
                <div class="container">
                <h1 class="hidden-text">Thoughts are nonexistent, until they get exposed to another human mind</h1>
                </div>
            </mdb-col>
            </mdb-row>
            </mdb-mask>
        </div>

        <mdb-view class="d-flex justify-content-center">
          <img
            src="https://i.ibb.co/pRCbXjj/Ebene-6.png"
            class="hover-shadow d-flex justify-content-center toto"
            alt="Embrace Color"
          />
            <mdb-mask class="mask" flexCenter>
            <mdb-row class="mb-5 pb-5 text-center">
            <mdb-col md="12" xl="8" class="mx-auto">
                <div class="container3">
                <h1 class="landing-text">IT'S  ALL  A  MATTER  OF  PERSPECTIVE</h1>
                </div>
            </mdb-col>
            </mdb-row>
            </mdb-mask>
        </mdb-view>
    </div>
</template>

<script>
import { mdbMask, mdbRow, mdbCol } from 'mdbvue';

export default {
  name: 'ParallaxWithBox',
    components: {
      mdbMask,
      mdbRow,
      mdbCol
    }
}
</script>

<style scoped>
h6 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
  color: white;
}
p {
  color: #969696;
  margin-bottom: 0;
}
.landing-text {
  font-size: 2.5em;
  font-weight: bold;
  color: white;
  mix-blend-mode: difference;
  margin-top: 40px;
  transition: transform .7s;
}
.landing-text-n {
  font-size: 2.5em;
  font-weight: bold;
  color: white;
  mix-blend-mode: difference;
  margin-top: 40px;
  -webkit-transition: color 1s ease-out;
  -moz-transition: color 1s ease-out;
  -o-transition: color 1s ease-out;
  transition: color 1s ease-out;
}
.landing-text-n:hover {
  color: black;
}
.container2 {
  transform: rotate(540deg);
  opacity: 0.9;
  transition: transform .8s;
}
.container2:hover {
  transform: rotate(360deg);
  opacity: 0.9;
}
.container3:hover .landing-text {
  transform: rotate(270deg);
  color: white;
  opacity: 0.9;
}
.hidden-text {
  font-size: 1.5em;
  color: black;
  mix-blend-mode: difference;
  font-style: italic;
  opacity: 0;
  transition: box-shadow .3s;
  transition: opacity .3s;
}
.hidden-text:hover {
  color: white;
  opacity: 1;
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
}
.container4:hover {
  color: white;
}
.hex-text {
  font-size: 2.5em;
  font-weight: bold;
  color: white;
  mix-blend-mode: difference;
}
.container2:hover .hex-text {
  transform: rotate(180deg);
  color: white;
  opacity: 0.9;
}
@media screen and (max-width: 500px) {
  .landing-text {
    display: none;
  }
}
.matrix {
  height: 50vh;
  background-color: black;
}

.toto {
  width: 100vw;
  height: auto;
}
</style>

<template>
  <div class="whole-page">
    <Parallax />
  </div>  
</template>

<script>
import Parallax from '@/components/Parallax';

export default {
  name: 'Welcome',
    components: {
      Parallax
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.whole-page {
  overflow-x: hidden;
  background-color: black;
}
</style>
<template>
  <div class="whole-page">
    <div class="box">
    </div> 

    <video class="video d-flex justify-content-center" autoplay muted loop>
      <source src="../assets/WackyAnimationLooped.webm" type="video/webm">
    </video>

    <div class="d-flex justify-content-center">
      <img
        src="https://i.ibb.co/Q8MmNyW/Power-of-Structure.png"
        class="img-fluid hover-shadow d-flex justify-content-center picture"
        alt="Gemälde - View of Toledo abstrahiert und multipliziert"
      />
    </div>

      <img
        src="https://i.ibb.co/cgmxTyY/dark-sphere-opac.png"
        class="img-fluid hover-shadow d-flex justify-content-center kubik"
        alt="Kubische Strukturen"
      />

      <img
        src="https://i.ibb.co/VJtXfx9/artefact-opacs.png"
        class="img-fluid hover-shadow d-flex justify-content-center picture5"
        alt="Kubische Strukturen"
      />

      <img
        src="https://i.ibb.co/W3nnMkP/planets-opac-t.png"
        class="img-fluid hover-shadow d-flex justify-content-center picture4"
        alt="Kubische Strukturen"
      />

    <video class="video d-flex justify-content-center" autoplay muted loop>
      <source src="../assets/WackyAnimationLooped.webm" type="video/webm">
    </video>

    <div>

      <img
        src="https://i.ibb.co/m8ktZSd/dark-sphere-opac-rotated.png"
        class="img-fluid hover-shadow d-flex justify-content-center picture"
        alt="Kubische Strukturen"
      />


    <div class="d-flex justify-content-center">
      <img
        src="https://i.ibb.co/4S6sDXS/Power-of-Shape-Opac.png"
        class="img-fluid hover-shadow d-flex justify-content-center kubik"
        alt="Gemälde - View of Toledo abstrahiert und multipliziert"
      />
    </div>
    </div>

    <video class="video d-flex justify-content-center" autoplay muted loop>
      <source src="../assets/WackyAnimationLooped.webm" type="video/webm">
    </video>

    <div class="video d-flex justify-content-center">
     <iframe width="1280" height="720" src="https://www.youtube.com/embed/hPErdI2mfyk?autoplay=1&controls=0" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>  
  </div>
</template>

<script>
  export default {
    name: "Inside",
    components: {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.whole-page {
  overflow-x: hidden;
}
.box {
  height: 30px
}
.video {
  width: 80vw;
  height: auto;
  margin: auto;
  z-index: 1;
  padding: 100px
}
.kubik {
  max-width: 80vw;
  height: auto;
  text-align: center;
  margin: auto;
}
  @media only screen and (max-width: 600px) {
    .video {
      padding: 30px;
    }
  }

  @media only screen and (max-width: 800px) {
    .picture .picture4 .picture5 {
      max-height: 30vh;
      width: auto;
    }
  }
  
.picture {
  position: absolute;
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 45s;
  overflow: -moz-hidden-unscrollable;
  max-height: 150vh;
  width: auto;
}
.picture5 {
  position: absolute;
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 30s;
  overflow: -moz-hidden-unscrollable;
  animation-delay           : -12s;
  max-height: 100vh;
  width: auto;
  transition: transform 3s;
}
.picture5:hover {
  transform: scale(1.2);
}
.picture4 {
  position: absolute;
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 35s;
  overflow: -moz-hidden-unscrollable;
  animation-delay           : -5s;
  max-height: 150vh;
  width: auto;
  transition: transform 3s;
}
.picture4:hover {
  transform: scale(0.8);
}
@-webkit-keyframes run {
  0% {
    left: -80%;
  }
  50% {
    left: 80%;
  }
  100% {
    left: -80%;    
  }
}

</style>

<template>
    <mdb-navbar dark transparent style="z-index: 2">
      <mdb-navbar-toggler>
        <mdb-navbar-nav center>
          <router-link to="/welcome" class="text">
            <mdb-nav-item waves-fixed class="text">WELCOME</mdb-nav-item>
          </router-link>
          <router-link to="/inside" class="text">
            <mdb-nav-item waves-fixed class="text">INSIDE</mdb-nav-item>
          </router-link>
          <router-link to="/my" class="text">
            <mdb-nav-item waves-fixed class="text">MY</mdb-nav-item>
          </router-link>
          <router-link to="/head" class="text">
            <mdb-nav-item waves-fixed class="text">HEAD</mdb-nav-item>
          </router-link>
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-navbar>
</template>

<script>
import {
  mdbNavbar,
  mdbNavItem,
  mdbNavbarNav,
  mdbNavbarToggler,
} from 'mdbvue';
export default {
  name: 'Navbar',
  components: {
    mdbNavbar,
    mdbNavItem,
    mdbNavbarNav,
    mdbNavbarToggler,
  },
};
</script>

<style>
.text {
  text-decoration: none;
  color: blue;
  font-size: larger;
}
.text:hover {
  text-decoration: none;
  color: black;
 
}
.navbar .dropdown-menu a:hover {
  color: inherit !important;
}
</style>

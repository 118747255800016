<template>
  <div class="whole-page">
    <Tunnel />
  </div>
</template>

<script>
import Tunnel from '@/components/Tunnel';

export default {
  name: 'Inside',
    components: {
      Tunnel
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.whole-page {
  overflow-x: hidden;
  background-color: black;
}
</style>

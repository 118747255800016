<template>
  <div id="app">
    <Navbar class="navigator"/>
    <MobileNav class="navigator-mobile"/>
    <div>
      <router-view/>
    </div>
    <Footer class="footer"/>
  </div>
</template>

<script>
import Footer from './Footer.vue';
import MobileNav from './MobileNav.vue';
import Navbar from './Navbar.vue'
export default {
  name: 'App',
  components: {
    Navbar,
    MobileNav,
    Footer
  },
};
</script>

<style>
#app {
  max-width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: black;
}
html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
::-webkit-scrollbar-thumb {
    background: green;
}
body {
  background-color: black;
}
.text {
  text-decoration: none;
  color: white;
}
.text:hover {
  text-decoration: none;
  color: black;
}
  @media only screen and (max-width: 950px) {
    .navigator {
      display: none;
    }
  }
  @media only screen and (min-width: 950px) {
    .navigator-mobile {
      display: none;
    }
  }
</style>

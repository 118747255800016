import Vue from 'vue';
import Router from 'vue-router';
import Head from '@/views/Head';
import Welcome from '@/views/Welcome';
import My from '@/views/My';
import Inside from '@/views/Inside';
import EasterEgg from '@/views/EasterEgg';

Vue.use(Router);

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: '/welcome',
      name: 'Welcome',
      component: Welcome
    },
    {
      path:  '/inside',
      name: 'Inside',
      component: Inside
    },
    {
      path:  '/my',
      name: 'My',
      component: My
    },
    {
      path:  '/head',
      name: 'Head',
      component: Head
    },
    {
      path: '/10011010001',
      name: 'easter-egg',
      component: EasterEgg
    },

    {
      path:   '*',
      redirect: {
        name: 'Welcome',
      }
    }
  ]
});

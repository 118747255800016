<template>
  <div class="whole-page">
    <ImageSet />
  </div>  
</template>

<script>
import ImageSet from '../components/ImageSet.vue';

export default {
  name: 'My',
    components: {
      ImageSet
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.whole-page {
    overflow-x: hidden;
    background-color: black;
}
</style>

